// Importa los módulos necesarios
import React, { useState, useEffect, useRef } from "react";
import Navbar from './Components/NavBarLite';
import Boton from './Components/Boton';
import "../css/nosotros2.css";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

import { HiMiniCpuChip, HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

import Nosotros1 from "./img/WBEP/Nosotros1.webp";
import Nosotros2 from "./img/WBEP/Nosotros2.webp";

const obtenerIniciales = (nombreCompleto) => {
  const partes = nombreCompleto.split(' ');
  return partes.map((parte) => parte.charAt(0).toUpperCase()).join('.');
};

// Definición del componente Home
function Home() {
  useEffect(() => {
    document.body.classList.add("body-nosotros");
    return () => {
      document.body.classList.remove("body-nosotros");
    };
  }, []);

  // Estado para almacenar el ancho de la ventana
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  // Hook para actualizar el ancho de la ventana en tiempo real
  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    // Escuchar el evento de cambio de tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Navbar />

      <div className="padreNosotros">
        <div>
          <div>
            <h1>Quiénes Somos</h1>
            <p>Somos un equipo familiar apasionado por la química y la innovación, formado por tres hermanos: Bayron, Héctor y Génesis. 
              Nos entusiasma colaborar con la industria para ofrecer soluciones efectivas a sus problemas y agilizar los trámites necesarios para el cumplimiento de normativas. 
              Con una alta resiliencia y un compromiso inquebrantable, estamos felices de poder ser parte de los procesos que ayudan a las empresas a optimizar su gestión y garantizar la calidad en sus operaciones. Juntos, aportamos experiencia en química, automatización y finanzas para ofrecer un servicio de confianza.</p>
            <div>
              <Link className="linkNosotros" to="/servicios">Conoce más</Link>
              <Link className="linkNosotros" to="/consultar">Contáctanos</Link>
            </div>
          </div>
          <div><img src={Nosotros1} alt="Imagen 1" /></div>
        </div>

        <div> 
          <h1>Nuestra Misión, Visión y Valores</h1>
          <div>
            <div>
              <h2>Misión</h2>
              <p>Ser un socio estratégico confiable que impulse el éxito de nuestros clientes a través de soluciones tecnológicas a la vanguardia,
                brindando un servicio excepcional y un compromiso inquebrantable con la calidad.</p>
            </div>
            <div>
              <h2>Visión</h2>
              <p>Convertirnos en líderes reconocidos en la industria, destacándonos por nuestra innovación, excelencia y
                enfoque en las necesidades de nuestros clientes.</p>
            </div>
            <div>
              <h2>Valores</h2>
              <p>Integridad</p>
              <p>Excelencia</p>
              <p>Innovación</p>
              <p>Trabajo en Equipo</p>
              <p>Orientación al Cliente</p>
            </div>
          </div>
        </div>

        <div>
          <div><img src={Nosotros2} alt="Imagen 2" /></div>
          <div>
            <div>
              <h2>Nuestro Compromiso</h2>
            </div>
            <div>
              <div>
                <h2>1. Compromiso con la Calidad</h2>
                <p>Nos esforzamos por brindar soluciones de la más alta calidad, superando las expectativas de nuestros clientes en todo momento.</p>
              </div>
              <div>
                <h2>2. Compromiso con la Innovación</h2>
                <p>Mantenemos un enfoque constante en la innovación, adoptando las últimas tecnologías y tendencias para ofrecer soluciones vanguardistas a nuestros clientes.</p>
              </div>
              <div>
                <h2>3. Compromiso con la Sostenibilidad</h2>
                <p>Nos esforzamos por ser una empresa socialmente responsable, adoptando prácticas sostenibles y promoviendo la responsabilidad ambiental.</p>
              </div>
              <div>
                <h2>4. Compromiso con el Servicio al Cliente</h2>
                <p>Nuestro objetivo es brindar un servicio excepcional a nuestros clientes, respondiendo a sus necesidades con prontitud y eficiencia.</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div>
              <h2>Presentación de las Personas Colaboradoras</h2>
            </div>
            <div>
              <div>
                <div>
                  <HiOutlineClipboardDocumentCheck
                    color="#7BD3BD"
                    size={60}
                  />
                  <h2>{anchoPantalla < 855 ? obtenerIniciales("Bayron Leiva Gamboa") : "Bayron Leiva Gamboa"}</h2>
                </div>
                <h2>Regente General</h2>
                <p>Químico graduado de la Universidad de Costa Rica, especializado en el control de calidad y análisis de productos químicos. Con amplia experiencia en proyectos de investigación y gestión de normativas, lidero la parte técnica y química del negocio, asegurando que todos nuestros servicios cumplan con los estándares.</p>
              </div>
              <div>
                <div>
                  <HiMiniCpuChip
                    color="#7BD3BD"
                    size={60}
                  />
                  <h2>{anchoPantalla < 855 ? obtenerIniciales("Héctor Leiva Gamboa") : "Héctor Leiva Gamboa"}</h2>
                </div>
                <h2>Encargado de Automatización</h2>
                <p>Estudiante de Mecatrónica en el Instituto Tecnológico de Costa Rica (TEC). Actualmente en formación, su enfoque en la automatización permitirá optimizar los sistemas de gestión en las empresas, facilitando la implementación de soluciones tecnológicas que mejorarán la eficiencia operativa de nuestros clientes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Boton />
    </div>
  );
}

// Exporta el componente Home
export default Home;
